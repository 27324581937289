const TYPE = {
    SEA: "sea",
    DATA: "data",
    CITY: "city",
    THINGS: "things"
}
// {
//     img: require("@/assets/images/1-1.png"),
//         title: "水底地形展示平台",
//     key: "while",
//     type: [TYPE.SEA],
//     carouseImages: [
//     require("@/assets/images/1-1.png"),
//     require("@/assets/images/1-2.png"),
//     require("@/assets/images/1-3.png"),
// ],//走马灯图片
// },
export default [
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/1-1.png",
        title: "水底地形展示平台",
        key: "while",
        type: [TYPE.SEA],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/1-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/1-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/1-3.png",
        ],//走马灯图片
        carouselUrl: "http://web.whale.shgis.com.cn/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/2-1.png",
        title: "桥梁数字孪生平台",
        key: "xihu",
        type: [TYPE.SEA, TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/2-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/2-4.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/2-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/2-3.png",
        ],
        carouselUrl: "http://121.40.248.247:9330/#/home-page"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-1.png",
        title: "园林绿化数字化系统",
        key: "zhyl",
        type: [TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-3.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-4.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-5.png",
        ],
        carouselUrl: "http://122.227.167.234:7202/#/demo"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/4-1.png",
        title: "郑氏十七房",
        key: "zssqf",
        type: [TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/4-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/4-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/4-3.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/4-4.png",
        ],
        carouselUrl: "http://1.15.139.95:2033/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/5-1.png",
        title: "智慧港航系统",
        key: "zhghxt",
        type: [TYPE.THINGS],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/5-1.png",
        ],
        carouselUrl: "http://121.40.248.247:9200/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/6-1.png",
        title: "船闸变形监测",
        key: "czbxjc",
        type: [TYPE.DATA],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/6-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/6-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/6-3.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/6-4.png",
        ],
        carouselUrl: "http://1.15.139.95:5005/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/7-1.png",
        title: "变形监测系统",
        key: "bl-bxjc",
        type: [TYPE.DATA],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/7-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/7-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/7-3.png",
        ],
        carouselUrl: "http://121.40.248.247:10180/#/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-1.png",
        title: "变形监测系统2",
        key: "ssl-bxjc",
        type: [TYPE.DATA],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-3.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-4.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-5.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/8-6.png",
        ],
        carouselUrl: "http://sll.shgis.com.cn/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/9-1.png",
        title: "流速流向实时监测平台",
        key: "cbd",
        type: [TYPE.THINGS],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/9-1.png"
        ],
        carouselUrl: "http://web.cbd.shgis.com.cn/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/10-1.png",
        title: "交通路网平台",
        key: "pyzx",
        type: [TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/10-1.png",
        ],
        carouselUrl: "http://123.60.150.191/itms_gc/#/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/11-1.png",
        title: "智慧电箱",
        key: "zjdx",
        type: [TYPE.THINGS],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/11-1.png",
        ],
        //carouselUrl: "http://120.26.195.192:10012/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/12-1.png",
        title: "智慧工地",
        key: "hsx",
        type: [TYPE.THINGS, TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/12-1.png",
        ],
        carouselUrl: "http://tongxiang.shgis.com.cn/"
    },
    {
        img: "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/13-1.png",
        title: "智慧交通工程管理系统",
        key: "zhjtglxt",
        type: [TYPE.CITY],
        carouseImages: [
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/13-1.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/13-2.png",
            "https://shchwhale1.oss-cn-hangzhou.aliyuncs.com/showweb/images/3-3.png",
        ],
        carouselUrl: "http://123.60.150.191/pingyang/#/sy"
    },
]
<template>
  <div style="display: flex">
    <div v-for="menu of menuList" :class="menuChoose===menu.key?'btn-choose':'btn'" @click="onClick(menu.key)">{{
        menu.label
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: ["menuChoose", "menuList"],
  emits: ['update:menuChoose'],
  methods: {
    onClick(key) {
      this.$emit('update:menuChoose', key)
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  margin-right: 0.1rem;
  border-radius: 0.1rem;
  width: 0.5rem;
  height: 0.17rem;
  color: white;
  text-align: center;
  letter-spacing: 0;
  font-size: 0.07rem;
  cursor: pointer;
  background: hsla(0, 0%, 100%, .1);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: hsla(0, 0%, 80%, .1);
  }
}

.btn-choose {
  @extend .btn;
  background-color: #008dff;

  &:hover {
    background-color: #007aff;
  }
}
</style>